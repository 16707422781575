<template>
  <div class='indexbox'>
    <div v-loading='box_loading'>
      <el-form ref="form" :model="form" :rules="rules" class="formbox">
        <div class="imgbox">
          <img alt="" src="../../assets/img/logo.png">
          <p></p>
        </div>
        <el-form-item v-if="type==1" prop="email">
          <span class="form_tit">Email*</span>
          <el-input v-model="form.email" clearable></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <span class="form_tit">Password*</span>
          <el-input v-model="form.password" clearable maxlength="18" show-password type="password"></el-input>
        </el-form-item>

        <el-form-item v-if="type==2" prop="re_password">
          <span class="form_tit">Confirm Password*</span>
          <el-input v-model="form.re_password" clearable maxlength="18" show-password type="password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button :loading='loading' type="primary" @click.native.prevent="onSubmit">Confirm</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import {Checkbox, CheckboxGroup} from 'vant';

export default {
  components: {
    VanCheckbox: Checkbox,
    VanCheckboxGroup: CheckboxGroup,
  },
  data() {
    return {
      invite: '',
      type: 2,//1 老用户 ，输入邮箱和密码  2  新用户 输入密码和确认密码

      form: {
        email: '',
        password: '',
        re_password: '',
      },

      rules: {
        email: [
          {required: true, trigger: "blur", message: 'Please Enter Email'}
        ],
        password: [
          {required: true, trigger: "blur", message: 'Please Enter Password'}
        ],
        re_password: [
          {required: true, trigger: "blur", message: 'Please Enter Confirm  Password'}
        ],
      },

      loading: false,
      box_loading: false,
      checked: true,
    };
  },
  computed: {},
  watch: {},
  methods: {
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.$post({
            url: "/app/invite_code/accept",
            data: {
              id: this.invite,
              ...this.form
            },
            success: () => {
              this.$message({
                message: 'Success',
                type: 'success'
              });
              setTimeout(() => {
                this.$router.replace('login')
                this.loading = false
              }, 1500);
            },
            tip: () => {
              setTimeout(() => {
                this.loading = false
              }, 1500);
            },
          })
        }
      });
    },
    getdata() {
      this.box_loading = true
      this.$post({
        url: "/app/invite_code/queryCode",
        data: {
          id: this.invite
        },
        success: (res) => {
          this.type = res.data.type
          this.box_loading = false
        },
        tip: () => {
          this.box_loading = false
        },
      })
    }
  },
  created() {
    if (this.$route.query.id) {
      this.invite = this.$route.query.id
      this.getdata()
    }
  }
}
</script>
<style lang='scss' scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 80px;

  .formbox {
    width: 480px;
    padding: 10px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .imgbox {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 310px;
        height: 160px;
      }

      p {
        width: 100%;
        text-align: center;
        color: #3b3b3b;
        font-size: 26px;
        font-weight: 600;
        font-family: PingFang SC;
        padding: 40px 0;
      }
    }

    .password {
      width: 100%;
      color: #3b3b3b;
      font-size: 16px;

      a {
        color: $color;
        font-size: 16px;
      }

      margin-bottom: 40px;
    }

    /deep/ .el-form-item {
      width: 100%;
      margin-bottom: 30px !important;

      .el-input {
        .el-input__inner {
          height: 50px;
        }
      }

      .el-form-item__content {
        width: 100%;

        .el-button {
          width: 100%;
          height: 48px;
          background: $color !important;
          border-radius: 5px;
          border-color: $color;
          font-size: 20px;
          font-weight: bold;
          color: #FFFFFF !important;
        }
      }
    }

    .registerbox {
      width: 100%;
      text-align: center;
      color: #3b3b3b;
      font-size: 16px;
      padding-top: 10px;

      a {
        color: $color;
        font-size: 16px;
      }
    }
  }
}
</style>
